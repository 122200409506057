import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import router from "@/router";
export default function useAdditionalValuesList() {
  // Use toast
  const toast = useToast();

  const refAdditionalValueListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "degeradi",
      label: VueI18n.t("additionalValueName"),
      sortable: true,
    },
    { key: "formul", label: VueI18n.t("formula"), sortable: true },
    { key: "edit", label: VueI18n.t("edit") },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(15);
  const totalAdditionalValues = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const additionalValues = ref(null);
  const allAdditionalValues = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refAdditionalValueListTable.value
      ? refAdditionalValueListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          (localItemsCount ? 1 : 0) +
          perPage.value <
        totalAdditionalValues.value
          ? perPage.value * (currentPage.value - 1) +
            (localItemsCount ? 1 : 0) +
            perPage.value
          : totalAdditionalValues.value,
      of: totalAdditionalValues.value,
    };
  });

  const refetchData = (force) => {
    // refAdditionalValueListTable.value.refresh();
    force = force || false;
    fetchAdditionalValues(force);
  };

  watch([roleFilter, planFilter, statusFilter], () => {
    refetchData();
  });

  const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const searchQueryChanged = debounce(() => {
    refetchData();
  }, 200);

  const fetchAdditionalValues = async (force) => {
    if (force || searchQuery.value == "" || allAdditionalValues.value == null) {
      allAdditionalValues.value = await store
        .dispatch("plantsModule/getAdditionalValues")
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: VueI18n.t("xFetchError", {
                value: VueI18n.t("additionalValues"),
              }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      additionalValues.value = allAdditionalValues.value;
    }
    if (searchQuery.value != "") {
      setTimeout(() => {}, 1000);

      var eq = [];
      for (let index = 0; index < allAdditionalValues.value.length; index++) {
        const element = allAdditionalValues.value[index];

        if (
          `${element.hammadde}`
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          `${element.besinMaddesi}`
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          `${element.formul}`
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        ) {
          eq.push(element);
        }
      }
      additionalValues.value = eq;
    }
    totalAdditionalValues.value = additionalValues.value.length;

    return additionalValues.value;
  };

  const deleteAdditionalValue = (ctx) => {
    store
      .dispatch("plantsModule/deleteAdditionalValue", ctx)
      .then((response) => {
        refetchData(true);
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("equation") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "success",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("equation") }),
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchAdditionalValues,
    tableColumns,
    perPage,
    currentPage,
    totalAdditionalValues,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAdditionalValueListTable,
    deleteAdditionalValue,
    searchQueryChanged,
    refetchData,
    additionalValues,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
