<template>
  <b-sidebar
    id="edit-additionalvalue-sidebar"
    :visible="isEditAdditionalValueSidebarActive"
    bg-variant="white"
    sidebar-class="custom-sidebar"
    class="custom-sidebar"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation(resetblankadditionalvalue).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("additionalValueEdit") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankadditionalvalue
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- ingredients -->

          <b-form-group :label="$t('valueName')" label-for="degeradi">
            <b-form-input
              id="degeradi"
              v-model="blankAdditionalValueData.degeradi"
              trim
              placeholder=""
            ></b-form-input>
          </b-form-group>

          <!-- Formul -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="formul"
          >
            <b-form-group :label="$t('formul')" label-for="formul">
              <b-form-input
                id="formul"
                v-model="blankAdditionalValueData.formul"
                autofocus
                :state="
                  formValidation(resetblankadditionalvalue).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{
                loading ? "İşlem Devam Ediyor. Lütfen Bekleyiniz." : $t("edit")
              }}
            </b-button>
            <b-button
              v-if="loading"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>

        <div>
          <b-table
            small
            responsive="sm"
            :items="nutrients"
            :fields="nutrientTableFields"
            :tbody-tr-class="rowClass"
            @row-clicked="(val) => rowClicked(val)"
          >
            <template #cell(count)="data">
              <b-badge variant="success">
                {{
                  blankAdditionalValueData.formul.split(
                    "[B" + data.item.kod + "]"
                  ).length - 1
                }}</b-badge
              >
            </template>
            <template #cell(nutrientname)="data">
              <small>
                {{ data.item.nutrientname }}
              </small>
            </template>
            <template #cell(kod)="data">
              <small>
                {{ data.item.kod }}
              </small>
            </template>
            <template #cell(birim)="data">
              <small>
                {{ data.item.birim }}
              </small>
            </template>
            <template #cell(remove)="data">
              <small>
                <feather-icon
                  icon="XIcon"
                  class="xIcon"
                  @click.stop="removeNutrient(data.item.kod)"
                ></feather-icon>
              </small>
            </template>
          </b-table>
        </div>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
  BTable,
  BBadge,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // BCardActions,
    BTable,
    BBadge,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditAdditionalValueSidebarActive",
    event: "update:is-edit-additionalvalue-sidebar-active",
  },
  props: {
    isEditAdditionalValueSidebarActive: {
      type: Boolean,
      required: true,
    },
    additionalvalue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      required,
      blankAdditionalValueData: {
        id: 0,
        ingredientid: 0,
        nutrientid: 0,
        formul: "",
      },
      ingredients: [],
      nutrients: [],
      operators: [
        { label: "=", value: "=" },
        { label: "<", value: "<" },
        { label: ">", value: ">" },
        { label: "<=", value: "<=" },
        { label: ">=", value: ">=" },
        { label: "<>", value: "<>" },
      ],

      nutrientTableFields: [
        { key: "count", label: "" },
        { key: "kod", label: this.$t("code") },
        { key: "nutrientname", label: this.$t("nutrientName") },
        { key: "birim", label: this.$t("unit") },
        { key: "remove", label: "" },
      ],
      formValidation: formValidation,
    };
  },
  async mounted() {
    this.ingredients = await store.dispatch(
      "plantsModule/fetchIngredientsForEsitlikler",
      store.state.app.selectedPlantId
    );
    this.ingredients.unshift({
      id: 0,
      ingredientname: "Genel Formül",
    });
    this.nutrients = await store.dispatch(
      "plantsModule/fetchNutrients",
      store.state.app.selectedPlantId
    );
  },
  methods: {
    async changed(val) {
      if (val == true)
        if (this.additionalvalue.item)
          this.blankAdditionalValueData = {
            id: this.additionalvalue.item.id,
            ingredientid: this.additionalvalue.item.hammaddeid,
            nutrientid: this.additionalvalue.item.besinMaddesiid,
            formul: this.additionalvalue.item.formul,
            ...this.additionalvalue.item,
          };
        else if (this.additionalvalue.id)
          this.blankAdditionalValueData = {
            id: this.additionalvalue.id,
            ingredientid: this.additionalvalue.hammaddeid,
            nutrientid: this.additionalvalue.besinMaddesiid,
            formul: this.additionalvalue.formul,
            ...this.additionalvalue,
          };
      this.$emit("update:is-edit-additionalvalue-sidebar-active", val);
    },
    rowClass(item, type) {
      if (item && type === "row") {
        if (
          this.blankAdditionalValueData.formul.includes("[B" + item.kod + "]")
        ) {
          return "selected-row";
        } else {
          return "bg-gray-100";
        }
      } else {
        return null;
      }
    },
    rowClicked(val) {
      if (this.blankAdditionalValueData.formul.trim().endsWith("]")) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("warning"),
            icon: "CheckIcon",
            variant: "warning",
            text: this.$t("firstSelectAnOperator"),
          },
        });
      } else this.blankAdditionalValueData.formul += "[B" + val.kod + "]";
    },
    //remove last nutrient from string
    removeNutrient(val) {
      var bStr = "[B" + val + "]";
      var lastIndex = this.blankAdditionalValueData.formul.lastIndexOf(bStr);
      if (lastIndex == -1) {
        return;
      }

      var newStr =
        this.blankAdditionalValueData.formul.substring(0, lastIndex) +
        this.blankAdditionalValueData.formul.substring(
          lastIndex + bStr.length + 1,
          this.blankAdditionalValueData.formul.length
        );
      this.blankAdditionalValueData.formul = newStr;
    },
    onSubmit() {
      this.loading = true;
      this.blankAdditionalValueData = {
        ...this.blankAdditionalValueData,
      };
      store
        .dispatch(
          "plantsModule/editAdditionalValue",
          this.blankAdditionalValueData
        )
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-additional-value-sidebar-active", false);
          this.loading = false;
        });
    },
    async resetblankadditionalvalue() {
      this.blankAdditionalValueData = {
        id: 0,
        ingredientid: 0,
        nutrientid: 0,
        formul: "",
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-additionalvalue-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style>
.selected-row {
  background-color: #f5f5f5;
  color: black !important;
}
.selected-row > td {
  color: black !important;
}
</style>

<style>
.custom-sidebar {
  width: 40rem !important;
}
.xIcon:hover {
  cursor: pointer;
  color: red;
  z-index: 1;
}
</style>
